import { useRef } from "react"
import useOnClickOutside from "@/lib/hooks/useOnClickOutside"
import useMenuItems from "@/lib/helpers/menu"
import Social from "@/common/social/social"
import Menu from "@/components/common/menu/menu"
import Close from "@/components/common/icons/close"
import Burger from "@/components/common/icons/menu"
import { useAdminBar } from "@/lib/contexts/appContext"
import classNames from "classnames/bind"
import styles from "./primary.module.css"
import { submenu } from "./menu.module.css"

export default function Primary({ isActive, setActive, isNarrow, hasMounted }) {
  const offCanvasRef = useRef(null)
  const cx = classNames.bind(styles)
  const adminBar = useAdminBar()

  useOnClickOutside(offCanvasRef, () => setActive("offCanvas", false), isActive)

  const items = [].concat(
    useMenuItems("primary-bramiljoval") ?? [],
    useMenuItems("top-bramiljoval") ?? []
  )

  if (items.length === 0) {
    return null
  }

  if (hasMounted && !isNarrow) {
    return null
  }

  return (
    <div
      className={classNames(styles.primary)}
      ref={offCanvasRef}
      role="primary">
      <button
        id="menubutton"
        type="button"
        aria-label={isActive ? "Stäng meny" : "Öppna meny"}
        aria-haspopup="true"
        aria-controls="primary-menu"
        className="toggle"
        onClick={() => setActive("offCanvas", !isActive)}>
        {isActive ? <Close className="close" /> : <Burger className="burger" />}
      </button>
      {items.length > 0 && (
        <div
          className={cx(
            { submenu: true, on: isActive, "has-admin-bar": adminBar },
            submenu
          )}>
          {isActive && (
            <nav id="primary-menu">
              <Menu
                menuClasses="primary"
                items={items}
                location="primary"
                ariaLabelledby="menubutton"
              />
              <Social styles={styles} location="primary" />
            </nav>
          )}
        </div>
      )}
    </div>
  )
}
