import Item from "./item"
import classNames from "classnames"

export default function Menu({
  menuClasses,
  items,
  location,
  parentOpen = false,
  caret = false,
  hover = false,
  depth = 0,
  handleOverlay,
  ariaLabelledby
}) {
  if (!items || items.length === 0) {
    return null
  }

  return (
    <ul
      className={classNames([
        menuClasses,
        { "has-caret": caret, "has-hover": hover }
      ])}
      role="menu"
      aria-labelledby={ariaLabelledby}
      data-location={location + "-menu"}
      data-depth={depth}
      onMouseEnter={() => hover && handleOverlay(location, true)}
      onMouseLeave={() => hover && handleOverlay(location, false)}>
      {items.map((item, index) => (
        <Item
          depth={depth}
          caret={caret}
          hover={hover}
          parentOpen={parentOpen}
          key={location + item.url + index}
          location={location}
          item={item}
        />
      ))}
    </ul>
  )
}
